<template>
  <b-container fluid class="wrapper p-5">
    <b-row
      style="
        border: 2px solid #2d3648;
        border-radius: 10px;
        background-color: #fff;
      "
      class="p-4 ml-4 mr-4 pb-5"
    >
      <b-col>
        <h3 class="mb-4">
          {{ $t("page.plan.title") }}
        </h3>
        <h6
          class="d-flex align-items-center justify-content-center font-weight-light"
        >
          <span>{{ $t("page.plan.billedMonthly") }}</span>
          <span class="ml-2 mr-2"
            ><div class="toggle-switch" @click="toggle">
              <div :class="['slider', { active: isActive }]"></div></div
          ></span>
          <span>{{ $t("page.plan.billedYearly") }}</span>
        </h6>
        <b-row class="mt-2">
          <b-col class="plan p-4 ml-2 mr-2 mt-2"
            ><div>
              <img
                src="../assets/general-icons/icon-zap-off.svg"
                alt=""
                class="plan-icon"
              />
            </div>
            <h3 class="mt-3">
              {{ $t("page.plan.basic.title") }}
            </h3>
            <h5 class="font-weight-normal">{{ $t("page.plan.basic.free") }}</h5>
            <h6 class="mt-4 font-weight-light">
              1/4 {{ $t("page.plan.basic.freeAnalysisUsed") }}
            </h6>
            <b-button class="mt-3 current-btn" variant="white">
              Current
            </b-button>
          </b-col>
          <b-col class="plan p-4 ml-2 mr-2 mt-2"
            ><div>
              <img
                src="../assets/general-icons/icon-zap.svg"
                alt=""
                class="plan-icon"
              />
            </div>
            <h3 class="mt-3">
              {{ $t("page.plan.pro.title") }}
            </h3>
            <h5 class="font-weight-normal">
              $ 14.90 {{ $t("page.plan.month") }}
            </h5>
            <h6 class="font-weight-light mt-4">
              <ul class="pl-4">
                <li>
                  {{ $t("page.plan.pro.unlimited") }}
                </li>
                <li>
                  {{ $t("page.plan.pro.features") }}
                </li>
              </ul>
            </h6>
            <b-button class="mt-3" variant="dark"> Current </b-button>
          </b-col>
          <b-col class="plan p-4 ml-2 mr-2 mt-2"
            ><div class="d-flex">
              <img
                src="../assets/general-icons/icon-zap.svg"
                alt=""
                class="plan-icon"
              /><img
                src="../assets/general-icons/icon-zap.svg"
                alt=""
                class="plan-icon"
              />
            </div>
            <h3 class="mt-3">
              {{ $t("page.plan.extreme.title") }}
            </h3>
            <h5 class="font-weight-normal">
              $ 34.90 {{ $t("page.plan.month") }}
            </h5>
            <h6 class="font-weight-light mt-4">
              <ul class="pl-4">
                <li>
                  {{ $t("page.plan.extreme.unlimited") }}
                </li>
                <li>
                  {{ $t("page.plan.extreme.features") }}
                </li>
              </ul>
            </h6>
            <b-button class="mt-3" variant="dark"> Current </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    toggle() {
      this.isActive = !this.isActive;
      this.$emit("toggle", this.isActive);
    },
  },
};
</script>

<style scoped>
.plan {
  border: 2px solid #2d3648;
  border-radius: 10px;
  background-color: #fff;
}
.plan-icon {
  width: 40px;
  height: 40px;
}
.current-btn {
  background-color: #edf0f7;
  border: transparent;
}
.toggle-switch {
  width: 40px;
  height: 24px;
  background-color: #fff;
  border-radius: 12px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: solid 2px #2d3648;
}

.slider {
  width: 16px;
  height: 16px;
  background-color: #2d3648;
  border-radius: 50%;
  position: absolute;
  transition: transform 0.3s, background-color 0.3s;
  left: 2px;
}

.slider.active {
  transform: translateX(16px);
  background-color: #2d3648;
}
</style>
